<template>
  <div class="disclosure">
    <h1>Audi Fleet Charging T3</h1>
    <h2>Promotion Terms and Conditions</h2>
    <p class="primary-color">Effective as of July 1, 2020</p>
    <p>Electrify America and Audi are pleased to provide this promotional charging program to select Audi drivers.</p>
    <p>
      By clicking “Agree” below, you agree to receive up to 4,000 kilowatt hours (kWh) (or, for charging stations where
      pricing is calculated on a per-minute basis, the per-minute equivalent of up to 4,000 kWh) of complimentary
      charging on Electrify America’s network of electric vehicle charging stations on the Audi EV provided to you by
      your employer or other organization, subject to these Promotion Terms and Conditions. The first enrolled driver
      for that vehicle will receive 4,000 kilowatt hours, and subsequent drivers for the same vehicle will receive the
      kilowatt hours remaining for that vehicle’s allotted complimentary charging. Please refer to the Plan Details
      screen in the Electrify America app, accessible by navigating to the
      <strong>Profile</strong> icon in top right-hand corner of map and selecting <strong>“Plan Details,”</strong> to
      track the total amount of complimentary kWh associated with your account.
    </p>
    <p>There is no additional fee, purchase, or subscription required for your access to this promotion.</p>
    <p>
      After signing up, you can redeem this complimentary credit during your initiation of a charging session at any
      Electrify America charging station, exclusively by using the Electrify America app “Swipe to start” or NFC Pass
      feature with the appropriate plan selected. This complimentary charging may not be initiated using the interface
      screen or buttons on the charger.
    </p>
    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong>
    </p>
    <ul>
      <li>
        You may <strong>only</strong> use this complimentary credit for charging the eligible vehicle under this program
        whose VIN you submitted when signing up for this promotion on your Electrify America account.
        <strong>No other vehicle is eligible to receive this credit through your account,</strong> whether owned by you
        or others.
      </li>
      <li>
        Complimentary charging kWh (or per-minute equivalent) <strong>does not include</strong> any parking or idle fees
        assessed at the charging station.
      </li>
      <li>
        You must follow all product, vehicle, safety, and technical documentation included with the vehicle when
        charging at an Electrify America charging station.
      </li>
      <li>
        You must use the correct connector type and charge at the correct power level for the eligible vehicle under
        this program at the charging station.
      </li>
      <li>
        You acknowledge that you forfeit any remaining credit from this promotion on your account when you return the
        vehicle to your employer or other organization.
      </li>
    </ul>
    <p>
      After you have used all of the available credit at Electrify America charging stations, you will not be able to
      reload or add more credit to your promotional plan. In addition, this promotion will automatically end forty-eight
      (48) months after the date that the eligible vehicle under this program is first registered for this program,
      following which you will not be able to access or use the remaining kWh credit on your account from this promotion
      (if any).
      <strong>
        Please note that the duration of this credit may be subject to change depending on the parameters of the
        promotional program selected by your organization.</strong
      >
      Refer to the Plan Details screen in the Electrify America app, accessible by navigating to the
      <strong>Profile</strong> icon in top right-hand corner of map and selecting <strong>“Plan Details,”</strong> to
      track the remaining program duration associated with your account.
    </p>
    <p>
      This credit is not returnable or redeemable for any cash value, and may not be sold, loaned, or otherwise
      distributed to any third party for any reason. You may not transfer any portion of the credit to any other
      Electrify America account.
    </p>
    <p>
      You acknowledge that your use of Electrify America’s services is subject to the Electrify America Terms of Use
      available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link> and that
      your use of the Electrify America mobile app is subject to the Terms of Use accessible by navigating to the
      <strong>Profile</strong> icon in top right-hand corner of map, selecting <strong>“Legal”</strong> and then
      selecting <router-link class="medium" :to="{ name: 'terms-en' }">“Terms of Use”</router-link> &
      <router-link class="medium" :to="{ name: 'privacy-en' }">“Privacy Policy.”</router-link>
      Please read these Terms of Use documents carefully for information about how you can pay through your account for
      charging session kWh (or per-minute equivalent) outside of the complimentary kWh (or per-minute equivalent)
      offered under this promotion.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services, and when
      charging your vehicle at Electrify America charging stations.
    </p>
    <p>
      Electrify America reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify America determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify America Terms of Use, these Promotion Terms and Conditions, or any
      other contract between you and Electrify America; (b) have engaged in charging sessions that are excessive,
      fraudulent, or otherwise indicate an unauthorized or unintended use of this complimentary promotion; or (c) have
      engaged in any illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify
      America services, the Electrify America mobile app, or an Electrify America charging station.
    </p>
    <p>
      Electrify America reserves the right to end this complimentary promotion at any time before its automatic
      termination date for any reason, in its sole discretion. Following the termination or expiration of this program
      for any reason, your access to the complimentary promotion will immediately cease, and you may not use or redeem
      any remaining portion of the complimentary charging kWh (or per-minute equivalent). At that time, your account
      will automatically convert to a basic Electrify America “Pass” account, at no additional fee or charge to you
      (neither one-time nor recurring). You may, but are not obligated to, sign up for a different type of subscription
      via the Electrify America mobile app.
    </p>
  </div>
</template>

<script>
export default {
  name: 'bespoke-disclosure-t3',
  metaInfo: {
    title: 'Audi Fleet Charging | Electrify America',
    meta: [{ name: 'robots', content: 'noindex' }],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/audi-fleet-charging-t3-disclosure/' }],
  },
};
</script>
